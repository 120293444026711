.button {
  white-space: nowrap;
}

.buttonRow {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
  margin-top: 1rem;

  .button {
    margin-left: 1rem;
    margin-bottom: .5rem;
  }

  .button:first-child {
    margin-left: 0;
  }
}
