.col-sm-3, .col-sm-4, .col-sm-6, .col-sm-8, .col-md-3, .col-md-4, .col-md-6, .col-md-8 {
  padding-left: 15px;
  padding-right: 15px;
}

.col-xs-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-xs-4 {
  flex: 0 0 33.33%;
  max-width: 33.33%;
}

.col-xs-5 {
  flex: 0 0 41.67%;
  max-width: 41.67%;
}

.col-xs-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-xs-8 {
  flex: 0 0 66.67%;
  max-width: 66.67%;
}

@media (min-width: 576px) {
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 33.33%;
    max-width: 33.33%;
  }

  .col-sm-5 {
    flex: 0 0 41.67%;
    max-width: 41.67%;
  }

  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-8 {
    flex: 0 0 66.67%;
    max-width: 66.67%;
  }
}

@media (min-width: 768px) {
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-4 {
    flex: 0 0 33.33%;
    max-width: 33.33%;
  }
  
  .col-md-5 {
    flex: 0 0 41.67%;
    max-width: 41.67%;
  }

  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-8 {
    flex: 0 0 66.67%;
    max-width: 66.67%;
  }
}
