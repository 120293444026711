@import '_colors';

$diameter: 50px;
$thickness: 2px;

$innerDiameter: $diameter - 2 * $thickness;
$radius: calc($diameter / 2);
$innerRadius: calc($innerDiameter / 2);

.navigation {
  justify-content: center;
  border-radius: 0.25em;
  padding-top: 1rem;
  user-select: none;
  position: relative;
}

.tabNumber {
  display: inline-block;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  text-align: center;
  position: relative;
  padding-top: 8px;
  font-size: 1rem;
}

.tabLabel {
  padding-left: 1em;
  padding-right: 1em;
}

.tabItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  color: $darkGreen;
  text-decoration: none;

  &:hover,
  &:active,
  &:focus {
    color: $darkGreen;
  }
}

.tabLineLeft {
  height: 2px;
  background-color: $darkGreen;
  position: absolute;
  left: 0;
  right: calc(50% + 1.4em);
  top: calc(24px + 0.5rem);
}

.tabLineRight {
  height: 2px;
  background-color: $darkGreen;
  position: absolute;
  left: calc(50% + 1.4em);
  right: 0;
  top: calc(24px + 0.5rem);
}

[aria-selected=true] .tabNumber,
.completed .tabNumber {
  background-color: $darkGreen;
  color: white;
}

.subTabHeader {

  i,
  svg {
    padding-left: 2px;
    padding-right: 2px;
  }
}

.disabled .tabNumber {
  color: $lightgray;
}

.disabled {
  cursor: default;
}

@media (max-width: 800px) and (min-width: 420px) {
  .tabLabel {
    display: none;
  }
}

@media (max-width: 420px) {
  .tabItem {
    padding: 0.5rem 0.25rem;
  }

  .tabLabel {
    display: none;
  }
}

@media (max-width: 260px) {
  .tabItem {

    i,
    svg {
      font-size: 0.6rem;
      margin-left: -1px;
      margin-right: -1px;
    }
  }
}

@media (max-width: 800px) {

  .tabLineLeft,
  .tabLineRight {
    visibility: hidden;
  }
}

.circular {
  width: $diameter;
  height: $diameter;
  position: relative;

  .inner {
    position: absolute;
    z-index: 6;
    top: 50%;
    left: 50%;
    width: $innerDiameter;
    height: $innerDiameter;
    margin: (-$innerRadius) 0 0 (-$innerRadius);
    background: white;
    border-radius: 100%;
  }

  .children {
    position: absolute;
    z-index: 7;
    top: 50%;
    left: 50%;
  }

  .children2 {
    margin-top: -50%;
    margin-left: -50%;
  }

  .circle {}

  .grayCircle {
    position: absolute;
    height: 100%;
    width: 100%;
    border: $thickness solid $lightgray;
    border-radius: 100%;
  }

  .bar {
    position: absolute;
    height: 100%;
    width: 100%;
    background: $darkGreen;
    clip: rect(0px, $diameter, $diameter, $radius);
    border-radius: 100%;
  }

  .progress {
    position: absolute;
    height: 100%;
    width: 100%;
    background: $darkGreen;
    border-radius: 100%;
  }

  .coverLeft {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 5;
    background: white;
    border-radius: 100%;
    clip: rect(0, $radius, $diameter, 0);
  }

  .coverRight {
    position: absolute;
    height: 100%;
    width: 100%;
    background: $darkGreen;
    border-radius: 100%;
    clip: rect(0, $diameter, $diameter, $radius);
  }

  .coverLeftCircle {
    position: absolute;
    height: 100%;
    width: 100%;
    border: $thickness solid $lightgray;
    border-radius: 100%;
    z-index: 6;
  }
}