.form {
  margin-bottom: 1rem;
}

.inputGroup {}

.input {}

.checkboxwrap {
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;
}

.checkboxlabel {
  margin-top: 0.32rem;
  padding-left: 0.5rem;
}

.checkbox {
  position: relative;
  top: 4px;
}

.suffix {
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: .61rem;
  margin-bottom: 0;
  font-size: .9rem;
  font-weight: 400;
  line-height: 1.5;
  color: #5d5d5d;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #eae9e9;
  border-radius: .25rem;

  border-top-left-radius: 0;
  border-bottom-left-radius: 0;

  margin-left: -1px;
}