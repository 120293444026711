@import './colors';

.group {
  margin-bottom: 1rem;
}

.radio {
  padding-bottom: 10px;
  position: relative;
  display: flex;

  input {
    width: 23px;
    height: 23px;
    padding-bottom: 5px;
    margin-top: 0;
    flex-shrink: 0;
    flex-grow: 0;
  }

  input:checked ~ .checkbox .checkboxInner {
    background-color: $button;
  }

  input:disabled ~ .checkbox .checkboxInner {
    background-color: inherit;
  }

  input:checked:disabled ~ .checkbox .checkboxInner {
    background-color: $lightGrayBorder;
  }

  label {
    margin-left: 5px;
  }
}

.radioHidden {
  display: none;
}

.checkbox {
  border: 1px solid $lightGrayBorder;
  border-radius: 4px;
  padding: 4px;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  background-color: white;
}

.checkboxInner {
  content: '&nbsp;';
  background-color: transparent;
  width: 15px;
  height: 15px;
  border-radius: 3px;
}

.radio:hover .checkboxInner {
  background-color: $lightGrayBorder;
}
