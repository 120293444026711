.table td {
  border-top: none;
  text-align: right;
  padding: .5rem;
  vertical-align: top;
}

.header {
  td, th {
    font-weight: bold;
    border-bottom: 2px solid black;
    text-align: left;
  }
}

.total {
  font-weight: bold;
}
