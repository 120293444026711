@import './colors';

.gallery {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
}

.image, .selectItem {
  min-width: 220px;
  box-shadow: 0px 0px 1px #888;
  margin: 16px;
  text-align: center;
  background-color: white;
  border-radius: 7px;
  border: 1px solid $lightGrayBorder;
}

.imageWrapper {
  background-color: #CCD6D5;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  padding: 24px;
}

.imageWrapperNoBorder {
  background-color: #CCD6D5;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;

  img {
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
  }
}

.selectItem {
  cursor: pointer;
  user-select: none;
}

.selectItem.disabled {
  cursor: default;
}

.selectItem.selected {
  border-color: $button;
}

.labelWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px;
}

.label {
  flex-grow: 1;
  text-align: left;
  padding-left: 16px;

  p:last-child {
    margin-bottom: 0;
  }
}
.label:first-child {
  padding-left: 0;
}

.checkbox {
  border: 1px solid $lightGrayBorder;
  border-radius: 4px;
  padding: 4px;
}

.checkboxInner {
  content: "&nbsp;";
  background-color: transparent;
  width: 15px;
  height: 15px;
  border-radius: 3px;
}

.selectItem:hover .checkboxInner {
  background-color: $lightGrayBorder;
}

.selectItem.selected .checkboxInner {
  background-color: $button;
}

.selectItem.disabled:hover .checkboxInner {
  background-color: inherit;
}

.selectItem.selected.disabled .checkboxInner {
  background-color: $lightGrayBorder;
}
