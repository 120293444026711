@import '_colors';

.progress {
  display: flex;
  overflow: hidden;
  border-radius: 0.15rem;
  height: 0.5rem;
  font-size: 0.75rem;
  background-color: $backgroundGrey;
  margin-right: 1rem;
}

.progressBar {
  background-color: $button;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  transition: width .6s ease;
}
