.pagination {

  .count,
  .page {
    color: var(--brand-color-gunsmoke);
    margin-right: 15px;
    margin-left: 15px;
    font-size: 18px;
    white-space: nowrap;
  }

  .count {
    flex: 1;
    margin-left: 0;
    white-space: nowrap;
  }

  button {
    margin-right: 5px;
    margin-left: 5px;
    padding-left: 25px;
    padding-right: 25px;

    &:last-of-type {
      margin-right: 0;
    }
  }
}

.itemsPerPage {
  color: var(--brand-color-gunsmoke);
  font-size: 18px;
  display: inline-block;
  margin-right: 0.4em;
  white-space: nowrap;

  text-overflow: ellipsis;
  overflow: hidden;
}