@import '_colors';

$borderRadius: 10px;

.group {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
  flex-wrap: wrap;
}

.wrapper {
  border-radius: $borderRadius;
  border: 1px solid $lightgray;
  width: 210px;
  margin: 1rem;

  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.imgwrapper {
  border-top-left-radius: $borderRadius;
  border-top-right-radius: $borderRadius;
  background-color: $buttonBackground;
  height: 160px;
  border-bottom: 1px solid $lightgray;
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  img {
    max-width: 200px;
    max-height: 150px;
  }

  button {
    padding: 5px;
//    font-size: 1rem !important;
  }
}

.photoindex {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;

  background-color: rgba(255, 255, 255, 0.6);

  padding: 7px;
  text-align: center;
}

.buttons {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  .delete {
    color: $error;

    &:hover {
      color: lighten($error, 10%);
    }
  }
}

.previous {
  position: absolute;
  left: 0;
  font-size: 1.5em !important;
}

.next {
  position: absolute;
  right: 0;
  font-size: 1.5em !important;
}

.uploader {
  border-top-left-radius: $borderRadius;
  border-top-right-radius: $borderRadius;
  background-color: $buttonBackground;
  height: 160px;
  border-bottom: 1px solid $lightgray;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
}

.uploader:hover .addIcon {
  background-color: lighten($button, 5%);
}

.addIcon {
  display: inline-block;
  width: 64px;
  height: 64px;
  background-color: $button;
  border-radius: 100%;
  color: white;
  font-size: 32px;
  text-align: center;

  i, svg {
    position: relative;
    top: 8px;
  }
}

.addIconSmall {
  display: inline-block;
  background-color: $button;
  border-radius: 100%;
  color: white;
  font-size: 20px;
  text-align: center;
  width: 40px;
  height: 40px;
  line-height: 23px;

  i, svg {
    position: relative;
    top: 8px;
  }
}

.uploaderSmall {
  position: absolute !important;
  right: 4px;
  top: 4px;
}

.uploaderSmall:hover .addIconSmall {
  background-color: lighten($button, 5%);
}

.label {
  margin: 1rem;
}


.fileinputButton {
  position: relative;
  overflow: hidden;
  cursor: pointer;
}
.fileinputButton input {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  opacity: 0;
  -ms-filter: alpha(opacity=0);
  font-size: 200px !important;
  direction: ltr;
  cursor: pointer;
}

/* Fixes for IE < 8 */
@media screen\9 {
  .fileinputButton input {
    filter: alpha(opacity=0);
    font-size: 100%;
    height: 100%;
  }
}

.error {
  color: $error;
  font-size: 16px;
  position: absolute;
  width: 100%;
  left: 0;
  bottom: -16px;
  color: red;
}
