.sessions {
  overflow-x: scroll;
  white-space: nowrap;

  td {
    overflow-x: hidden;
    padding-left: 5px;
    padding-right: 5px;
  }
}

.buttons {
  :global(.btn) {
    padding-left: 13px;
    padding-right: 13px;
  }
}