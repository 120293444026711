$grannySmith: #99c031;
$darkGreen: #333334; //-- dark grey
//$darkGreen: #717171; -- neutral grey
$darkBlue: #4e6d7d;
$lightGreen: #abc0df;
$smappeeInfinity: #7f9f98;
$darkOlive: #15241f;

$water: #009fc7;
$dark: #15241f;
$darkerGreen: #074b46;
$backgroundGrey: #f8f8f8;

$error: #ef0e48;
$electricity: #fec331;
$solar: #99c031;
$gas: #eb6d01;

$background: #e9e9e9;
$headerColor: $darkOlive;
$textColor: $darkOlive;
$lightgray: #ddd;

$buttonBackground: #CCD6D5;
//$button: #256660;
$button: #333334;
//$button: #717171;
$lightGrayBorder: #E5E5E5;